import React , {useState} from 'react'
import SectionHeader from './SectionHeader'
import Member from './Member'
import { Link, useLocation } from 'react-router-dom'

import ReactPaginate from 'react-paginate';

import data from '../pageData.json'

import './css/MemberSection.css'

const title = 'Speaker of Meetup Conference '
const desc = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam et purus a odio finibus bibendum insit amet leo. Mauris feugiat erat tellus.'

const MemberSection = () => {

  const [members, setMembers] = useState(data[0].person)
  const [pageNumber, setPageNumber] = useState(0)

  const memberPerPage = window.innerWidth > 900 ? 6 : 1;
  const visited = pageNumber * memberPerPage

  const displayMembers = members.slice(visited, visited + memberPerPage)

  const location = useLocation();
  let memberData;

  if(location.pathname === '/'){
    memberData = members.slice(0, 3)
  } else {
    memberData = displayMembers
  }  
  const pageCount = Math.ceil(members.length / memberPerPage)

  const changePage = ({selected}) =>{
    setPageNumber(selected)
  }

  return (
    <div className='members'>
        <SectionHeader title={title} desc={desc}/>
        <div className='container'>
          <div className='members-cards'>
          {memberData.map(member =>
            <Member 
            key={member.id}
            id={member.id}
            img={member.img}
            name={member.name}
            position={member.position}
            />
        ) }
          </div>
        </div>
        {location.pathname === '/' &&
        <button className='btn members-btn'><Link to="/speakers" className="link">| &nbsp; Know More &nbsp; |</Link></button>
       }
        {location.pathname !== '/' && 
        <div className='container'>
         <ReactPaginate
          previousLabel={"<"}
          nextLabel={">"}
          pageCount={pageCount}
          onPageChange={changePage}
          containerClassName={"members-paginate"}
          activeClassName={"active"}
          marginPagesDisplayed={1}
          pageRangeDisplayed={1}
          />
          </div>
        }
        </div>
  )
}

export default MemberSection