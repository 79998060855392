import React from 'react'

import HeroSection from "../components/HeroSection";
import About from "../components/About";
import VideoSection from "../components/VideoSection";
import MemberSection from "../components/MemberSection";
import SponsorSection from "../components/SponsorSection";

const HomePage = () => {
  return (
    <div>
     <HeroSection/>
     <About /> 
     <VideoSection/>
     <MemberSection/>
     <SponsorSection/>
   </div>
  )
}

export default HomePage

 