import React from 'react'
import './css/SectionHeader.css'

const SectionHeader = ({title, desc}) => {
  return (
    <div className='container section-header'>
        <h2 className='section-header-title'>{title}</h2>
        <p className='section-header-desc'>{desc}</p>
    </div>
  )
}

export default SectionHeader