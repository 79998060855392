import React from 'react'
import SubpageHero from '../components/SubpageHero'
import Schedule from '../components/Schedule'

import './css/SchedulePage.css'

const SchedulePage = () => {
  return (
    <div className='schedule-page'>
        <SubpageHero/>
        <Schedule/>
    </div>
  )
}

export default SchedulePage