import { Routes,Route } from "react-router-dom";

import AboutPage from "./pages/AboutPage"
import HomePage from "./pages/HomePage";
import Footer from "./components/Footer";
import ScrollToTop from "./utils/ScrollToTop.js";
import ThankYouPage from "./pages/ThankYouPage";
import SpeakersPage from "./pages/SpeakersPage";
import SpeakerPage from "./pages/SpeakerPage";
import Page404 from "./pages/Page404";
import SchedulePage from "./pages/SchedulePage";

function App() {
  return (
    <div className="App">
     <ScrollToTop>
        <Routes>
          <Route path='/' element={<HomePage /> }></Route>
          <Route path='/about' element={<AboutPage /> }></Route>
          <Route path='/thank-you' element={<ThankYouPage /> }></Route>
          <Route path='/speakers' element={<SpeakersPage /> }></Route>
          <Route path='/speaker/:id' element={<SpeakerPage /> }></Route>
          <Route path='/schedule' element={<SchedulePage /> }></Route>
          <Route path='*' element={<Page404 />}></Route>
        </Routes>
        <Footer/>
      </ScrollToTop>
    </div>
  );
}

export default App;
