import React from 'react'
import SubpageHero from '../components/SubpageHero'
import SectionHeader from '../components/SectionHeader'
import { useParams } from 'react-router-dom'

import './css/SpeakerPage.css'

import data from '../pageData.json'
import Member from '../components/Member'

const Speaker = () => {
  
  const {id} = useParams()
  const userId = id-1
  const memberData = data[0].person[userId]

   let memberL = data[0].person.length
  // let randomMemberId = Math.floor(Math.random() * memberL)
const otherMembers = data[0].person
const copy = otherMembers.map(object => ({ ...object}))
const displayedMembers = copy.sort(() => Math.floor(Math.random() * memberL)).slice(0, 4);

  return (
    <div className='speaker'>
      <SubpageHero/>
      <SectionHeader title={memberData.name} desc={memberData.position}/>
      <div className='container'>
        <div className='speaker-bio'>
          <img src={memberData.img} alt={memberData.name} width="350px" height="430px"/>
          <div>
             <h4>About me</h4>
           
             <p style={{whiteSpace:"break-spaces"}}>{memberData.bio}</p>
          </div>
        </div>
        <div className='other-speakers'>
            <h2>Other Speakers</h2>  
            <div className='other-speakers-item'>
              { 

          // data[0].person.slice(0,3).map(item => 
          displayedMembers.map(item => 
               <Member
                key={item.id}
                id={item.id}
                name={item.name}
                img={item.img}
                position={item.position}
               />
              ) 
              }
            </div>
        </div>
      </div>
    </div>
  )
}

export default Speaker