import React from 'react'
import { Link } from 'react-router-dom'
import SubpageHero from '../components/SubpageHero'
import SectionHeader from '../components/SectionHeader'

const title = '404'
const desc = 'Page not found'

const Page404 = () => {
  return (
    <div> 
          <SubpageHero/>
          <SectionHeader title={title} desc={desc}/>
    <div className='container' style={{textAlign:"center", marginBottom:"100px"}}> 
          <button className='btn'><Link className="link" to='/'>Home Page</Link></button>
    </div>
    </div>
  )
}

export default Page404