import React from 'react'
import { useLocation } from 'react-router-dom'

import './css/SubpageHero.css'
import Navbar from './Navbar'

const SubpageHero = () => {
    const location = useLocation()
    //console.log(location.pathname);
    const pathelement = location.pathname.split('/');
    //console.log(pathelement);
    const pagetitle = pathelement.slice(pathelement.length-1).toString().replaceAll('-', ' ').toUpperCase() 
 
   
  return (
    <div className='subpage-hero'>
        <Navbar/>
        {!isNaN(pagetitle)
        ?  <h1 className='title'>SPEAKERS</h1>  
        :  <h1 className='title'>{pagetitle}</h1>
        }
    </div>
  )
}

export default SubpageHero