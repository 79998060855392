import React from 'react'
import SectionHeader from './SectionHeader'
import { Link } from 'react-router-dom'

import './css/About.css'

import el1 from '../assets/img/el1.png'
import img2 from '../assets/img/about2.jpg'
import img3 from '../assets/img/about3.jpg'
import img4 from '../assets/img/about4.jpg'

const sectiontitle = 'Your Are Invited To Our Meetup Conference';
const sectiondesc = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam et purus a odio finibus bibendum in sit amet leo. Mauris feugiat erat tellus.'

const About = () => {
  return (
    <div className='about'>
        <SectionHeader title={sectiontitle} desc={sectiondesc}/>
        <div className='container about-box'>
            <div className='about-img'>
               <img className='el1' src={el1} alt="aboutus" style={{width:'204px', height:'114px'}}/>  
               <img className='img4' src={img4} alt="aboutus" style={{width:'330px', height:'385px'}}/>
               <img className='img3' src={img3} alt="aboutus" style={{width:'330px', height:'222px'}}/>
               <img className='img2' src={img2} alt="aboutus" style={{width:'280px', height:'187px'}}/>
               <img className='el2' src={el1} alt="aboutus" style={{width:'204px', height:'114px'}}/>  
            </div>
            <div className='about-text'>
                <p className='big-letter'>we are so excited to introduce to you our new Unbounce Template called Conference. This Template is fully responsive and CMS ready, no coding skills required!</p>
                <p className='about-pdecor' style={{ marginTop:'50px', MarginBottom:'30px' }}>
                    Conference Template, also contains a lot of useful sections that you can edit or remove.</p>
                <p className='about-pdecor' style={{ marginBottom:'50px'}}>Conference Template, also contains a lot of useful sections that you can edit or remove.</p>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam et purus a odio finibus bibendum in sit amet leo. Mauris feugiat erat tellus.Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.  Separated they live in Bookmarksgrove.   Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam et purus a odio finibus.Lorem ipsum dolor sit amet, consectetur adipiscing elit. </p>
                <button className='btn about-btn'><Link className='link' to='/about'>| &nbsp; Know More &nbsp; |</Link></button>
            </div>
        </div>
    </div>
  )
}

export default About