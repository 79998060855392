import React from 'react'
import SectionHeader from './SectionHeader'

import './css/SponsorSection.css'

import data from '../pageData.json'

const title = 'Sponsored of Meetup Conference'
const desc = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam et purus a odio finibus bibendum insit amet leo. Mauris feugiat erat tellus.'

const SponsorSection = () => {
  return (
    <div className='sponsor'>
        <SectionHeader title={title} desc={desc}/>
        <div className='sponsor-list container'>
            {data[1].sponsor.map(item =>
            <div className='item' key={item.id}>
              <img src={item.img} alt="sponsor" width="150px" height="125px"/>     
            </div>
                )}
        </div>
    </div>
  )
}

export default SponsorSection