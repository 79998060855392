import React from 'react'

import './css/Venue.css'
import SectionHeader from './SectionHeader'
import {AiOutlineAim} from "react-icons/ai";
import {FaBed} from "react-icons/fa";

const title = 'Venue of Meetup Conference ';
const desc = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. bibendum in sit amet leo. Mauris feugiat erat tellus.'

const Venue = () => {
  return (
    <div className='container venue'>
        <div className='venue-content'>
            <SectionHeader title={title} desc={desc}/>
            <div className='venue-location'>
                <div className='venue-icon'><AiOutlineAim/></div>
                <div>
                    <h4>Location</h4>
                    <p>Lorem  ipsum dolor sit amet, consectetur adipiscing
elit. Etiam et purus a  odio finibus bibendum in sit amet
leo. Mauris feugiat erat tellus.</p>
                </div>
            </div>
            <div className='venue-accommodation'>
            <div className='venue-icon'><FaBed/></div>
                <div>
                    <h4>Accommodation</h4>
                    <p>Lorem  ipsum dolor sit amet, consectetur adipiscing
elit. Etiam et purus a  odio finibus bibendum in sit amet
leo. Mauris feugiat erat tellus.</p>
                </div>
            </div>
        </div>
        <div className='venue-img'></div>
    </div>
  )
}

export default Venue