import React,{useState, useEffect} from 'react'
import { GoogleMap, useJsApiLoader, Marker  } from '@react-google-maps/api';
import './css/Footer.css'
import { Link } from 'react-router-dom';
import  Form  from './Form';
import {AiOutlineArrowUp} from "react-icons/ai";

const Footer = () => {

  // button scroll totop
  const [scrollTopButton, setScrollTopButton] = useState(false)

  useEffect(()=>{
    window.addEventListener("scroll", () =>{
      if(window.scrollY > 200){
        setScrollTopButton(true)
      } else {
        setScrollTopButton(false)
      }
    })
  }, [])

  const scroolToTop = () => {
    window.scrollTo({
      top:0,
      behavior:'smooth'
    }) 
  }


  //google map config
  const center = {
    lat: 50.265406,
    lng: 19.024872
  };
  const containerStyle = {
    width: '100%',
    height: '600px'
  };
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY,
  })

  if (!isLoaded) return <div>Loading...</div>;

  return (
    <div className='footer'>
      {scrollTopButton && 
       <button className='btn scroll-top' onClick={scroolToTop}>
        <AiOutlineArrowUp/>
       </button>
      }
           <GoogleMap
             center={center}
             mapContainerStyle={containerStyle}
             zoom={16}
             >
                <Marker position={center}/>
           </GoogleMap>
            <Form/>
           <div className='footer-menu'>
              <ul className='footer-nav container'>
                <li><Link className='link' to='/'>Home</Link></li>
                <li><Link className='link' to='/about'>About</Link></li>
                <li>Privacy Policy</li>
                <li><Link className='link' to='/schedule'>Schedule</Link></li>
                <li className='btn'>| &nbsp; Buy ticket &nbsp; |</li>
            </ul>
            <p className='container text-center' style={{color:'#fff', margin:'20px auto 0'}}>All right reserved @ {new Date().getFullYear()}</p>
          </div>
    </div>
  )
}

export default Footer