import React, {useState } from 'react'
import SectionHeader from './SectionHeader'

import './css/VideoSection.css'
import play from '../assets/img/play.png'

const title = 'Check Out Our Intro Video Meetup Conference'
const desc = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam et purus a odio finibus bibendum insit amet leo. Mauris feugiat erat tellus.'

const VideoSection = () => {
    const[isVideo, setIsVideo] = useState(false)

    const hanldeVideo = () =>{
      setIsVideo(true)
    }
    const closeVideo = () =>{
      setIsVideo(false)
    }

  return (
    <div className='video'  >
        <SectionHeader title={title} desc={desc}/>
        <div onClick={hanldeVideo}>
          <img src={play} alt="play" />
        </div>
        {isVideo &&
        <div className='container video-modal'>
          <div className='video-frame'>
          <span className='video-close' onClick={closeVideo}>X</span>
           <iframe id="player" type="text/html" title="promovideo"
            src="https://www.youtube.com/embed/M7lc1UVf-VE?enablejsapi=1&origin=http://example.com"
            ></iframe>
          </div>
        </div>
         }
    </div>
  )
}

export default VideoSection