import React, { useRef } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import {useNavigate} from "react-router-dom"
import emailjs from '@emailjs/browser';

import './css/Form.css'

const schema = yup.object().shape({
    name: yup.string().max(60,'Imię nie może być dłuższe niż 60 znaków').required("Podaj swoje imię"),
    email: yup.string().email('Podaj poprawny adres mail').required("Adres email jest obowiązkowy"),
    ticketNumber: yup.number().typeError('Podaj poprawny numer biletu').positive().integer().test('lenght', 'Numer biletu składa się z 12 znaków', val => val.toString().length === 12).required('Wprowadź poprawny numer biletu'),
    acceptTerms: yup.bool().oneOf([true], 'Musisz wyrazić zgodę aby przesłać formularz')
  });

const Form = () => {
    const { register, handleSubmit, formState: { errors }, reset  } = useForm({
        // defaultValues:{
        //   name:'',
        //   email:'',
        //   ticketNumber:'',
        //   message:''
        // }
            resolver: yupResolver(schema),
      });

      const navigate = useNavigate();
      const form = useRef();

    const onSubmit = data => {
       // console.log(data.name)
         navigate('/thank-you', 
            {state:{
                name:data.name, 
                email:data.email,
                ticketNumber:data.ticketNumber,
                message:data.message
            }})
    emailjs.sendForm(
        'service_68uu6zf', 
        'template_1pr9wxi', 
        form.current, 
        'gfb8GUSL3r5b1Opt7'
        )
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
    reset();

    };
  //  console.log(errors);
    
    return (
      <div className='form-section'>
       <div className='container'>
        < div className='form-box'>
            <div className='form-info'>
                <h3 className='form-info-title'>Get In Touch</h3>
                <div className='form-info-content'>
                    Conference Center<br/>
                    ul. Korfantego 00<br/>
                    41-400 Katowice
                </div>
                <div className='form-info-content'>
                    +48 123 123 123<br/>
                    +48 321 321 321
                </div>
                <div className='form-info-content'>
                    eivent@eivent.com<br/>
                    info@eivent.com
                </div>
            </div>
        <form ref={form} className='form' onSubmit={handleSubmit(onSubmit)}>
            <div className='form-group'>
                <label htmlFor='name'>Name</label>
                <input id="name" type="text" {...register("name")} />
                {errors.name && <span className="form-error">{errors.name?.message}</span>}
            </div>
            <div className='form-group'>
                <label htmlFor='email'>Email</label>
                <input id="email" type="text" {...register("email")} />
                {errors.email && <span className="form-error">{errors.email?.message}</span>}
            </div>
            <div className='form-group'>
                <label htmlFor='ticket'>Ticket number</label>
                <input id ="ticket" type="text" {...register("ticketNumber")} />
                {errors.ticketNumber && <span className="form-error">{errors.ticketNumber?.message}</span>}
            </div>
            <div className='form-group'>
                <label htmlFor='message'>Message</label>
                <textarea rows='6' id="message" {...register("message", {})} />
            </div>
            <div className='acceptTerms'>
                <input type="checkbox" {...register('acceptTerms')} id="acceptTerms" />
                <label htmlFor="acceptTerms">Accept Terms & Conditions</label>
            </div>
            {errors.acceptTerms && <span className="form-error">{errors.acceptTerms?.message}</span>}
            <button type="submit" className='btn form-btn'>| &nbsp; Submit &nbsp; |</button>
            {/* <button onClick={() => reset()} type="button" className='btn form-btn'>| &nbsp; Reset &nbsp; |</button> */}
        </form>
        </div>
       </div>
      </div>
    );
}

export default Form