import React, {useState} from 'react'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom'

import './css/Member.css'

const Member = ({id, img, name,position, bio }) => {
    const location = useLocation();
  return (
<div className='member' key={id}> 
       {/* {console.log(member.img)}  */}
        <img src={img} alt="member"/>
         <div className='member-info'>
            <h3 className='name'>{name}</h3>
            <span>{position}</span>
          </div>
         {
         location.pathname === '1' &&
          <div className=''>
            <p>{bio}</p>
          </div>
        }
      <button className='btn'><Link className="link" to={`/speaker/${id}`}>Zobacz BIO</Link></button>
       </div>

  )
}

export default Member