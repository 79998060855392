import React, {useEffect, useState} from 'react'
import SectionHeader from './SectionHeader'
import {AiOutlineArrowLeft, AiOutlineArrowRight} from "react-icons/ai";

import './css/Testimonial.css'
import data from '../pageData.json'

const Testimonial = () => {

  const item  = data[3].testimonial 
    //console.log(item)
  const [itemIndex, setItemIndex] = useState(0)

  const lastIndex = item.length - 1

  const handlePrev = () => {
    if(itemIndex > 0){
      setItemIndex(itemIndex - 1)
    }
    if(itemIndex === 0){
      setItemIndex(lastIndex)
    }
   }
  
   const handleNext = () => {
    if(itemIndex < lastIndex){
      setItemIndex(itemIndex + 1)
    }
    if(itemIndex === lastIndex){
      setItemIndex(0)
    }
   }

  useEffect(() => {
   
    let slider = setInterval(() => {
      if (itemIndex < lastIndex) {
      setItemIndex(itemIndex + 1)
      } else {
        setItemIndex(0)
      }
    }, 2500);
    return () => clearInterval(slider)
 }, [itemIndex, item.length, lastIndex])



  return (
    <div className='testimonial container'>
         <div className='testimonial-img'></div>
         <div className='testimonial-content'>
            <SectionHeader title={'Your Opinion about Conference'} desc={''}/>
            
             <div className='testimonial-box'>
                    <div className='testimonial-content'>{item[itemIndex].opinion}</div>
                    <div>
                        <span className='testimonial-name'>{item[itemIndex].name}</span><br/>
                        <span style={{fontWeight:'bold'}}>{item[itemIndex].position}</span>
                    </div>
              </div> 
              <div className='testimonial-arrows'>
                <AiOutlineArrowLeft onClick={handlePrev}/>
                <AiOutlineArrowRight onClick={handleNext}/>  
              </div>    
          </div>
    </div>
  )
}

export default Testimonial