import React from 'react'
import SubpageHero from '../components/SubpageHero'
import SectionHeader from '../components/SectionHeader'
import { useLocation } from 'react-router-dom'

const style ={
    display:"flex",
    flexDirection:"Column",
    gap:"1rem",
    border:"3px solid var(--primary)",
    padding:"2rem",
    margin:"0 auto 100px",
    width:"80%"
}

const title = 'Dziękujemy za przesłanie zgłoszenia'
const desc = 'Przeanalizujemy Twoje zgłoszenie i odpowiemy na przesłane dane kontaktowe. Poniżej znajduje się podsumowanie Twojej wiadomosci.'

const ThankYouPage = () => {
    const { state } = useLocation();
  return (
    <div>
        <SubpageHero/>
        <SectionHeader title={title} desc={desc}/>
        <div className='container'>
            <div className='thank-you' style={style}>
                <div><b>Imię:</b> {state.name}</div>
                <div><b>Email:</b> {state.email}</div>
                <div><b>Ticket Number:</b> {state.ticketNumber}</div>
                <div><b>Message:</b> {state.message}</div>
           </div>
        </div>
    </div>
  )
}

export default ThankYouPage