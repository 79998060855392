import React from 'react'
import Countdown from 'react-countdown';
import './css/HeroSection.css'
import Navbar from './Navbar'
import { Link } from 'react-router-dom';

const renderer = ({ days, hours, minutes, seconds }) => {
      return ( 
      <>
                <div className='hero-timer-item'>
                    <p>{days}</p>
                    <span>DAYS</span>
                </div>
                <div className='hero-timer-item'>
                     <p>{hours}</p>
                     <span>HOURS</span>
                </div>
                <div className='hero-timer-item'>
                     <p>{minutes}</p>
                     <span>MINUTES</span>
                </div>
                <div className='hero-timer-item'>
                    <p>{seconds}</p>
                    <span>SECONDS</span>
                </div>
        </>
      )
  };    

const HeroSection = () => {

    const eventDate = new Date("2024-07-20T18:00:00").getTime()
    const todayDate = new Date().getTime()

    const finaldate = eventDate - todayDate

  return (
    <div className="hero">
        <Navbar/>
        <div className='container hero-info'>
           <h1 className='hero-title'>Meetup Conference</h1>
           <p className='hero-content'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut luctus orci a risus mollis imperdiet. Ut sit amet velit elementum, hendrerit leo a, auctor metus. Proin finibus dolor eget suscipit eleifend.</p>
        
            <div className='hero-timer'>
             <Countdown  renderer={renderer} date={Date.now() + finaldate} />
            </div>
            <button className='btn hero-btn'><Link className='link' to={'/schedule'}>| &nbsp; Know More &nbsp; |</Link></button>
        </div>
    </div>
  )
}

export default HeroSection