import React, {useState} from 'react'
import SectionHeader from './SectionHeader'
import { useLocation } from 'react-router-dom'

import data from '../pageData.json'

import './css/Schedule.css'

const title = 'Schedule of Meetup Conference'
const desc = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam et purus a odio finibus bibendum insit amet leo. Mauris feugiat erat tellus.'

const Schedule = () => {
    const [activeItem, setActiveItem] = useState('22')
    const location = useLocation()
   
    return (
   
    <div className='schedule'>
         <SectionHeader title={title} desc={desc}/>
         {
         location.pathname === '/schedule' ? (
            <div className='container schedule-items'>
            {data[2].schedule.map(item => 
                <div key={item.id} className='schedule-item'>
                    <div className='schedule-date'>
                        <span className='schedule-day'>{item.day}</span>
                        <span className='schedule-month'>{item.month}</span>
                    </div>
                    <div>
                    <span className='schedule-name'>{item.name}</span><br/> 
                    {item.speaker &&
                    <>
                    <span className='schedule-speaker'>{item.speaker}</span><br/>
                    </>
                    }
                    <span className='schedule-hour'>{item.hour}</span>
                    </div>
                    <div>
                        {item.info}
                    </div>
                </div>
            )}
            </div>
         ) : (
            <>
            <div className='container day-tabs'>
              <div onClick={() =>  setActiveItem('22')}>22 May</div>
              <div onClick={() =>  setActiveItem('23')}>23 May</div>
              <div onClick={() =>  setActiveItem('24')}>24 May</div>
            </div>
            <div className='container schedule-items'>
            {data[2].schedule.filter(filtered => filtered.day === activeItem).map(item => 
                <div key={item.id} className='schedule-item'>
                    <div className='schedule-date'>
                        <span className='schedule-day'>{item.day}</span>
                        <span className='schedule-month'>{item.month}</span>
                    </div>
                    <div>
                    <span className='schedule-name'>{item.name}</span><br/> 
                    {item.speaker &&
                    <>
                    <span className='schedule-speaker'>{item.speaker}</span><br/>
                    </>
                    }
                    <span className='schedule-hour'>{item.hour}</span>
                    </div>
                    <div>
                        {item.info}
                    </div>
                </div>
            )}
            </div>
        </>
         )       
        }
    </div>
  )
}

export default Schedule