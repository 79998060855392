import React, { useState }from 'react'
import './css/Navbar.css'
import { Link } from 'react-router-dom'

import {AiOutlineClose, AiOutlineMenu} from 'react-icons/ai'

const Navbar = () => {
    const[isMobile, setIsMobile] = useState(false)
    const[navColor, setNavColor] = useState(false)

    const styles = {
        iconStyle: {
           color: navColor ? '#555' : '#fff',
           marginTop: navColor ? '10px' : '0',
        }
    }

    const changeColor = () => {
        if(window.scrollY >= 120) {
            setNavColor(true) 
        } else {
            setNavColor(false) 
        }
    }
    window.addEventListener('scroll', changeColor);

    const handleMobileNav = () =>{
        setIsMobile(!isMobile)
    }

  return (
    <div className={navColor ? 'nav-row white' : 'nav-row'}>
     <nav className={navColor ? 'nav container  pt-0' : 'nav container'}>
        <span className={navColor ? 'logo black' : 'logo'}>
            <Link className='link' to='/'>Eivent.</Link>
        </span>
        <ul className='nav-menu'>
            <li><Link className='link' to='/'>Home</Link></li>
            <li><Link className='link' to='/about'>About</Link></li>
            <li><Link className='link' to='/speakers'>Speakers</Link></li>
            <li><Link className='link' to='/schedule'>Schedule</Link></li>
            <li className='btn'>| &nbsp; Buy ticket &nbsp; |</li>
        </ul>
        <ul className={ isMobile ? 'nav-menu-mobile active' :
                        'nav-menu-mobile'}>
            <li className='mobile-close' onClick={handleMobileNav}>
                <AiOutlineClose></AiOutlineClose>
            </li>
            <li><Link className='link' to='/about'>About</Link></li>
            <li><Link className='link' to='/speakers'>Speakers</Link></li>
            <li><Link className='link' to='/schedule'>Schedule</Link></li>
            <li className='btn'>| &nbsp; Buy ticket &nbsp; |</li>
        </ul>
        <div className='nav-mobile-icon' onClick={handleMobileNav}>
            {isMobile ? 
            <AiOutlineClose style={styles.iconStyle}></AiOutlineClose>
            :
            <AiOutlineMenu style={styles.iconStyle}></AiOutlineMenu>
            }
        </div>
       
      </nav>
    </div>
  )
}

export default Navbar