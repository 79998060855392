import React from 'react'
import SubpageHero from '../components/SubpageHero'
import MemberSection from '../components/MemberSection'

const title="Speaker of Meetup Conference "
const desc="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam et purus a odio finibus bibendum insit amet leo. Mauris feugiat erat tellus."

const SpeakersPage = () => {
  return (
    <div>
        <SubpageHero/>
        <MemberSection/>
    </div>
  )
}

export default SpeakersPage