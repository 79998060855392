import React from 'react'
import SubpageHero from '../components/SubpageHero'
import SectionHeader from '../components/SectionHeader'
import Schedule from '../components/Schedule'
import Venue from '../components/Venue'
import Testimonial from '../components/Testimonial'

const title = 'History of Our Event'
const desc = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam et purus a odio finibus bibendum insit amet leo. Mauris feugiat erat tellus.'

const About = () => {
  return (

    <div>
      <SubpageHero/>
      <SectionHeader title={title} desc={desc}/>
      <div className='container'>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam et purus a odio finibus bibendum insit amet leo. <br/><br/>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, 
      quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum 
      dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
      Mauris feugiat erat tellus.<br/><br/>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam et purus a odio finibus bibendum insit amet leo. Mauris feugiat erat tellus.
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam et purus a odio finibus bibendum insit amet leo. Mauris feugiat erat tellus.<br/><br/>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam et purus a odio finibus bibendum insit amet leo. Mauris feugiat erat tellus.
      </div>
      <Schedule/>
      <Venue/>
      <Testimonial/>
    </div>
  )
}

export default About